@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@200;400&display=swap');

$font-family-sans-serif:     'Roboto Condensed', sans-serif !default;

$font-size-base:              0.885rem !default;

// Import Custom Variables
@import "variables.scss";

$box-shadow:                  0 0.1rem 0.5rem rgba($black, .15);

$card-cap-bg:                       rgba($black, .02);
$card-bg:                           #FFF !default;

// Import Bootstrap
@import "bootstrap/scss/bootstrap.scss";
@import "/node_modules/bootstrap-icons/font/bootstrap-icons.scss";

// Import Custom SCSS
@import "global.scss";

// Layout
@import "layout/authentication.scss";
@import "layout/dashboard-default.scss";
@import "layout/dashboard-fixed.scss";
@import "layout/error.scss";

// Navigation
@import "navigation/nav.scss";
@import "navigation/topnav.scss";
@import "navigation/sidenav/sidenav.scss";
@import "navigation/sidenav/sidenav-dark.scss";
@import "navigation/sidenav/sidenav-light.scss";

