//
// Global
//

html,
body {
  height: 100%;
}

.bg-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($gray-300, 0.8);
}

#__next {
  height: 100vh;
}

.form-group {
  margin-bottom: 0.5rem;
}

.form-label {
  margin-bottom: 0.2rem !important;
}
